@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  padding: clamp(12px, 20px, 20px) 0;
  width: 100%;
  background-color: var(--color-purple-400);
  transition-property: transform, padding;
  transition-duration: 0.15s;

  &.hide {
    transform: translateY(-100%);
  }

  .container {
    max-width: 1394px;
  }
}
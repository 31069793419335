@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

h1,
h2,
h3 {
  overflow: hidden;
  font-family: var(--font-family-primary);
}

h1,
h2 {
  font-weight: var(--fw-600);
  line-height: 1.21;
}

h1 {
  margin-bottom: 24px;
  font-size: clamp(36px, 3rem + 1vi, 50px);
  color: var(--color-purple-800);
  letter-spacing: -0.5px;

  @include media(tablet)  {
    font-size: 34px;
    letter-spacing: -1px;
  }
}

h2 {
  font-size: clamp(32px, 2rem + 1vi, 40px);
  color: var(--color-purple-500);
  text-transform: capitalize;

  @include media(tablet)  {
    font-size: 32px;
  }
}

h3 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: var(--fw-700);
  color: var(--color-blue-700);
}

p {
  font-family: var(--font-family-primary);
  font-size: clamp(20px, 1rem + 1vi, 28px);
  line-height: 1.21;
  font-weight: var(--fw-400);
  color: var(--color-blue-770);
}
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

*[class].btn {
  display: inline-block;
  margin: 0;
  padding: 8px 20px;
  border: 1px solid var(--color-white);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  font-size: clamp(18px, 1rem + 1vi, 20px);
  line-height: 1.21;
  color: var(--color-black);
  text-align: center;
  background-color: var(--color-white);
  border-radius: 0;
  transition-property: background-color, color, border-color;
  transition-duration: 0.4s;
  transition-timing-function: ease-in-out;

  &:hover {
    background-color: var(--color-purple-200);
    border-color: var(--color-black);
  }

  &--reverse {
    color: var(--color-white);
    background-color: var(--color-red-400);
    border-color: var(--color-red-400);

    &:hover {
      background-color: var(--color-red-600);
      border-color: var(--color-red-600);
    }
  }

  &--large {
    padding: 20px 32px;
    font-size: clamp(20px, 1rem + 1vi, 22px);

    @include media(tablet) {
      padding: 16px 20px;
    }
  }

  &--accent {
    padding: 12px 31px;
    font-size: 16px;
    line-height: 1.5;
    color: var(--color-red-400);
    border-radius: var(--radius-xs);
    border-color: var(--color-red-400);

    &:hover {
      background-color: var(--color-red-600);
      border-color: var(--color-red-600);
      color: var(--color-white);
    }
  }

  &--send {
    max-width: fit-content;
    padding: 15px 22px;
    font-size: 18px;
    line-height: 1;
    color: var(--color-white);
    background-color: var(--color-dark);
    border-radius: var(--radius-s);

    &:hover {
      background-color: var(--color-gray-600);
      border-color: var(--color-gray-600);
      color: var(--color-milk-100)
    }
  }

  &--mobile {
    display: none;
    width: fit-content;
    margin: 0 auto;
  }

  @include media(tablet) {
    &--mobile {
      display: block;
    }
  }
}

*[class].swiper-button {
  width: 167px;
  height: 30px;
  top: calc(50% + 10px);
  right: 105px;

  &::after {
    content: '';
    position: absolute;
    inset: 0;
    background-image: url("../images/icons/arrow-next.svg");
    background-repeat: no-repeat;
    background-position: center right;
    background-size: cover;
  }

  @include media(tablet-wide) {
    right: 0;
    width: 60px;
  }

  @include media(mobile-sm) {
    width: 40px;
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.about {
  position: relative;
  padding-block: rem(130) rem(58);

  &::before {
    @include cover-background();

    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
    background-image: var(--icon-bg-wave);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    max-width: 1136px;
    width: 100%;
    margin: 0 auto;
  }

  &__box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
    max-width: 446px;
    width: 100%;
  }

  &__content {
    p {
      margin-bottom: 0;
      color: var(--color-blue-770);

      &:not(:last-of-type) {
        margin-bottom: rem(76);
      }
    }
  }

  &__img {
    position: absolute;
    right: 0;
    top: -52px;
    border-radius: var(--radius-main);
    z-index: -1;
  }

  &__phrase {
    margin-bottom: 8px;
    font-size: 22px;
    line-height: 1.18;
    color: var(--color-purple-500);
  }

  &__cards {
    max-width: 708px;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    justify-items: stretch;
    gap: rem(60);
  }

  &__card {
    padding: rem(20);
    background-color: var(--color-white);
    border-radius: calc(var(--radius-m) * 2);

    h3 {
      margin-bottom: 4px;
      font-size: 20px;
      line-height: 1.3;
    }

    p {
      font-size: 16px;
      line-height: 1.62;
    }
  }

  &--home {
    @include media(tablet-wide) {
      .about__image {
        position: relative;
      }
    }
  }

  &--history {
    padding: rem(70) 0;

    .about__inner {
      padding: 0 0 0 24px;
      align-items: center;

      @include media(tablet-wide) {
        padding: 0;
      }

      @include media(mobile-l) {
        flex-direction: column;
      }
    }

    .about__content {
      p {
        font-size: clamp(20px, 1rem + 1vi, 23px);

        &:not(:last-of-type) {
          margin-bottom: 20px;
        }
      }
    }

    img {
      object-fit: contain;
      object-position: center 70%;
    }
  }

  &--approach {
    padding: rem(149) 0 rem(103);

    &::before {
      background-image: var(--icon-wave-reverse);
    }

    .about__inner {
      gap: rem(40);
      max-width: 1230px;
    }

    .about__box {
      max-width: 476px;
      gap: 0;
    }

    h2 {
      margin-bottom: 26px;
    }

    .about__content {
      p {
        width: 87%;
        font-size: 23px;
      }
    }

    @include media(desktop) {
      .about__inner,
      .about__cards {
        gap: 30px
      }

      .about__inner {
        flex-direction: column;
      }

      .about__phrase {
        order: 1;
      }

      .about__content {
        order: 2;
      }

      .about__box,
      .about__cards {
        max-width: 100%;
      }
    }

    @include media(mobile-l) {
      .about__content {
        p {
          width: 100%;
        }
      }

      .about__cards {
        grid-template-columns: 1fr;
        gap: 20px;
      }
    }
  }

  &--service {
    padding: rem(130) 0;

    &::before {
      background-image: none;
    }

    .about__inner {
      justify-content: flex-start;
      gap: 0 rem(82);
    }

    .about__box {
      max-width: 492px;

      @include media(tablet) {
        max-width: 100%;
      }
    }

    .about__phrase {
      margin-bottom: 0;
    }

    h2 {
      font-size: clamp(34px, 2.5rem + 1vi, 48px);
      color: var(--color-blue-700);

      @include media(tablet) {
        font-size: 32px;
      }
    }

    .about__content {
      p {
        font-size: 23px;
      }
    }

    @include media(desktop) {
      .about__inner {
        gap: 30px;
      }
    }
  }

  &--bookkeeping {
    .about__content {
      width: 87%;
    }

    .about__image {
      min-height: 444px;

      img {
        position: absolute;
        right: 25px;
        top: 72px;
      }
    }

    @include media(desktop) {
      .about__image {
        img {
          position: static;
        }
      }

      .about__box {
        max-width: 100%;
      }

      .about__image {
        min-height: auto;
      }
    }

    @include media(mobile-l) {
      .about__inner {
        flex-direction: column;
      }

      .about__content {
        width: 100%;
      }
    }
  }

  &--reporting,
  &--payroll,
  &--auditing {
    .about__inner {
      gap: 0 rem(100);

      @include media(desktop) {
        gap: 30px;
      }
    }
  }

  &--tax {
    &::before {
      background-image: var(--icon-egg);
      width: 775px;
      height: 1115px;
      top: 0;
      left: 0;
      transform: translateY(-25%);
    }

    @include media(tablet) {
      &::before {
        width: 100%;
        height: 100%;
      }
    }

    @include media(mobile-l) {
      .about__inner {
        flex-direction: column;
      }
    }
  }

  &--auditing {
    &::before {
      background-image: var(--icon-bubble);
      width: 978px;
      height: 799px;
      top: 0;
      left: unset;
      right: 0;
      transform: translateY(-44%);
    }

    .about__content {
      width: 87%;
    }

    @include media(mobile-l) {
      .about__inner {
        flex-direction: column;
      }

      .about__content {
        width: 100%;
      }
    }
  }

  &--financial {
    .about__inner {
      gap: 0 rem(60);
    }

    @include media(desktop) {
      .about__inner {
        gap: 30px;
      }

      img {
        object-fit: contain;
      }
    }
  }

  &--benefits {
    padding: rem(130) 0;

    &::before {
      background-image: none;
    }

    h2 {
      max-width: 1136px;
      width: 100%;
      margin: 0 auto 10px;
      font-size: clamp(32px, 2rem + 1vi, 40px);
      color: var(--color-purple-500);
    }

    .about__inner {
      align-items: center;
      gap: 0 rem(80);
    }

    .about__box {
      max-width: 565px;
    }

    .about__content {
      p {
        font-size: clamp(18px, 2rem + 1vi, 28px);

        &:not(:last-of-type) {
          margin-bottom: 25px;
        }
      }

      b {
        font-weight: var(--fw-600);
      }
    }

    @include media(tablet) {
      h2 {
        margin-bottom: 30px;
      }

      .about__inner {
        flex-direction: column-reverse;
        gap: 30px;
      }

      .about__box {
        max-width: 100%;
      }

      .about__image,
      img {
        width: 100%;
        object-fit: contain;
        aspect-ratio: 4 / 2;
      }
    }
  }

  @include media(tablet-wide) {
    &__inner {
      gap: 20px;
      align-items: center;
    }

    &__img {
      inset: 0;
      filter: opacity(0.5) blur(1px) brightness(0.5);
    }

    img {
      height: auto;
    }
  }

  @include media(tablet) {
    padding: rem(44) 0;

    &__content {
      p:not(:last-of-type) {
        margin-bottom: 30px;
      }
    }

    &--service:not(.about--benefits) {
      padding: 44px 0 0;
    }
  }

  @include media(mobile-l) {
    &__inner {
      flex-direction: column-reverse;
    }

    &__box {
      max-width: 100%;
    }

    &__image,
    img {
      width: 100%;
    }

    img {
      height: unset;
      aspect-ratio: 4 / 3;
    }
  }
}
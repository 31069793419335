@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.clients {
  padding: rem(130) 0;

  &__inner {
    max-width: 1216px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: rem(20);
  }

  &__desc {
    margin-bottom: rem(60);
    font-size: 23px;
  }

  &__wrapper {
    position: relative;
  }

  &__slider {
    max-width: 812px;
    width: 100%;
    margin: 0;
  }

  &__slides {
    align-items: stretch !important;
  }

  &__slide {
    padding: rem(32) rem(24) rem(74);
    background: var(--gradient-accent);
    border-radius: var(--radius-main);

    h3 {
      margin-bottom: rem(75);
      font-size: clamp(20px, 1rem + 1vi, 28px);
      font-weight: var(--fw-600);
    }
  }

  @include media(tablet) {
    padding: 44px 0;

    &__slider {
      width: 80%;
    }

    &__slide {

      h3 {
        margin-bottom: 30px;
      }
    }
  }

  @include media(mobile-s) {
    &__slide {
      padding: rem(32) rem(10);
      
      p {
        font-size: 18px;
      }
    }
  }
}
@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.key {
  padding: rem(120) 0 rem(86);
  background: var(--gradient-section);

  &__inner {
    max-width: 1216px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: rem(60);
    text-align: center;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: rem(40);
    padding: 0 rem(28);
  }

  &__item {
    flex-basis: 31%;
  }

  &__year {
    position: relative;
    margin-bottom: 10px;
    padding-left: 86px;
    font-size: clamp(36px, 3rem + 1vi, 50px);
    line-height: 1.44;
    font-weight: var(--fw-700);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 60px;
      height: 60px;
    }

    &--inception::before {
      background-image: url("../images/icons/inception-icon.svg");
    }

    &--expansion::before {
      background-image: url("../images/icons/expansion-icon.svg");
    }

    &--technology::before {
      background-image: url("../images/icons/technology-icon.svg");
    }

    &--global::before {
      background-image: url("../images/icons/export-icon.svg");
    }

    &--industry::before {
      background-image: url("../images/icons/industry-icon.svg");
    }
  }

  &__name {
    margin-bottom: 10px;
    font-size: 22px;
  }

  &__desc {
    font-size: 23px;
  }

  @include media(desktop) {
    &__grid {
      justify-content: flex-start;
      padding: 0;
    }

    &__item {
      flex-basis: 45%;

      &:last-of-type {
        margin: 0 auto;
      }
    }
  }

  @include  media(tablet) {
    padding: rem(44) 0;

    h2 {
      margin-bottom: 30px;
    }
  }

  @include media(mobile-l) {
    &__item {
      flex: 100%;
    }

    &__year {
      padding-left: 70px;
      font-size: clamp(36px, 3rem + 1vi, 38px);
      &::before {
        top: 50%;
        width: 50px;
        height: 50px;
        transform: translateY(-50%);
      }
    }
  }
}

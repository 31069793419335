@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.hero {
  $root: &;
  padding: 0;

  .overlay:not(.hero--home .hero__inner) {
    &::before {
      opacity: 0;

      @include media(tablet) {
        opacity: 1;
      }
    }
  }

  &__inner {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: rem(80) 0;
    min-height: 727px;
    background-position: 86% -45px;
    background-repeat: no-repeat;
  }

  &__content {
    z-index: 1;
    margin-inline: rem(100) 0;
    max-width: 630px;
    width: 100%;
  }

  &__desc {
    max-width: 437px;
    width: 100%;
    margin-bottom: rem(40);
    color: var(--color-black);
  }

  &--home {
    .hero__desc {
      color: var(--color-white);
    }
  }

  &--about {
    .hero__inner {
      background-position: 81% 80px;
    }
  }

  &--services {
    .hero__inner {
      min-height: 661px;
      background-position: 84% 80px;
    }
  }

  &--works {
    .hero__inner {
      background-position: 89% 80px;
    }

    .hero__desc {
      max-width: 100%;
    }
  }

  &--security {
    .hero__inner {
      min-height: 868px;
      padding-top: rem(130);
      background-position: 100% 48px;
    }

    .hero__content {
      max-width: 828px;
      margin-inline: rem(50) 0;
    }

    h2 {
      margin-bottom: 20px;
    }

    .hero__desc {
      max-width: 100%;
      margin-bottom: 20px;
    }

    .hero__item {
      width: fit-content;
      padding: rem(28) rem(44);
      border-radius: calc(var(--radius-m) * 5);

      &:not(:last-of-type) {
        margin-bottom: 20px;
      }

      &:first-child {
        background-color: var(--color-purple-691);
      }

      &:nth-child(2) {
        background-color: var(--color-red-391);
      }

      &:last-child {
        background-color: var(--color-purple-391);
      }

      p {
        color: var(--color-white);
      }
    }

    @include media(desktop) {
      .hero__inner {
        min-height: 450px;
        padding: 44px 0;
        background-size: 40%;
        background-position: center right;
      }

      .hero__content {
        width: 70%;
        padding: 0;
      }
    }

    @include media(tablet) {
      .hero__content {
        margin: 0;
      }

      .hero__item {
        padding: 15px 10px;

        p {
          font-size: 18px;
          letter-spacing: -1px;
        }
      }
    }

    @include media(mobile-m) {
      .hero__content {
        width: 100%;
      }
    }
  }

  &--about,
  &--services,
  &--works {
    .hero__inner {
      @include media(tablet-wide) {
        background-position: 100% center;
        background-size: 40%;
      }

      @include media(tablet) {
        min-height: 450px;
      }
    }

    @include media(tablet-wide) {
      .hero__content {
        margin: 0;
        padding: 0 15px;
      }
    }

    @include media(tablet) {
      .hero__desc {
        color: var(--color-white);
      }
    }
  }

  @include media(tablet) {
    &__inner {
      position: relative;
      min-height: 450px;
      padding: 44px 0;
      background-image: none !important;

      &::after {
        @include cover-background;

        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 400px;
        height: 225px;
        background-image: url("../images/hero/home.png");
        z-index: -1;
      }
    }

    &__content {
      margin: 0 auto;
      padding: 0 15px;
    }

    h1 {
      width: 80%;
    }

    &__desc {
      margin-bottom: 20px;
    }

    &--about {
      .hero__inner::after {
        width: 280px;
        height: 280px;
        background-image: url("../images/hero/about.png");
      }
    }

    &--services {
      .hero__inner::after {
        width: 300px;
        height: 280px;
        background-image: url("../images/hero/services.png");
      }
    }

    &--works {
      .hero__inner::after {
        width: 300px;
        height: 240px;
        background-image: url("../images/hero/works.png");
      }
    }

    &--security {
      .hero__inner::after {
        width: 260px;
        height: 240px;
        background-image: url("../images/works/security.png");
      }
    }
  }

  @include media(mobile-m) {
    h1 {
      width: 100%;
    }

    &__inner {
      padding: 30px 0 rem(150);

      &::after {
        width: 250px;
        height: 150px;
      }
    }

    &--about {
      .hero__inner::after {
        width: 220px;
        height: 220px;
      }
    }

    &--services {
      .hero__inner::after {
        width: 240px;
        height: 220px;
      }
    }

    &--works {
      .hero__inner::after {
        width: 200px;
        height: 170px;
      }
    }

    &--security {
      .hero__inner::after {
        width: 180px;
        height: 200px;
        bottom: 70px;
      }
    }

  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins"as*;

.policy {
  padding: rem(86) 0;
  background: var(--gradient-secondary);

  h1 {
    margin-bottom: 10px;
    color: var(--color-black);
    text-align: center;
  }

  .container {
    padding: rem(60);
    background-color: var(--color-white);
    border-radius: var(--radius-secondary);
  }

  h2 {
    margin-bottom: 20px;
    font-size: clamp(20px, 1rem + 1vi, 28px);
    color: var(--color-black);
    text-transform: capitalize;
  }

  p,
  a,
  li {
    font-size: clamp(20px, 1rem + 1vi, 23px);
    color: var(--color-gray-700);
  }

  p {
    &:not(:last-child) {
      margin-bottom: rem(48);
    }
  }

  a {
    text-decoration: underline;
    color: var(--color-purple-800);
  }

  ul {
    margin-bottom: 30px;
    padding-left: 15px;
    font-weight: 500;
  }

  li {
    list-style-type: disc;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  @include media(tablet) {
    padding: 44px 0;

    .container {
      padding: 40px 15px;
      border-radius: calc(var(--radius-m) * 2);
    }

    p {
      &:not(:last-child) {
        margin-bottom: rem(20);
      }
    }

    ul {
      margin-bottom: 20px;
    }
  }

  @include media(mobile-m) {
    p,
    a,
    li {
      font-size: 18px;
    }
  }
}
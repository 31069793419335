@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
:root {
  --content-width: 1240px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width)  + (var(--container-offset) * 2));
  --font-family-primary: "Montserrat", sans-serif;
  --font-family-secondary: "Inter", sans-serif;
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;
  --color-white: #fff;
  --color-black: #111;
  --color-dark: #000207;
  --color-gray-100: #fbf8ff;
  --color-gray-400: #f2f2f2;
  --color-gray-700: #2c2c2e;
  --color-red-100: #fdedfc;
  --color-red-120: #faedff;
  --color-red-391: #ff8bd1e8;
  --color-red-400: #fd4c5c;
  --color-red-600: #b03d48;
  --color-purple-100: #eeedfd;
  --color-purple-200: #e3cefb;
  --color-purple-391: #d299ffe8;
  --color-purple-400: #c796ff;
  --color-purple-510: #781bff1a;
  --color-purple-500: #781bff;
  --color-purple-503: #781bff08;
  --color-purple-691: #9b8bffe8;
  --color-purple-800: #49009d;
  --color-blue-100: #e4efff;
  --color-blue-700: #000b33;
  --color-blue-770: #000b33b3;
  --color-blue-760: #000b3399;
  --shadow-main: 0px 4px 40px 0px #2b59ff14;
  --radius-round: 50%;
  --radius-main: 40px;
  --radius-secondary: 60px;
  --radius-m: 10px;
  --radius-s: 8px;
  --radius-xs: 3px;
  --gradient-main: linear-gradient(180deg, #ddc0ff 0%, #6615c4 100%);
  --gradient-secondary: linear-gradient(.13deg, #8c30f566 0.11%, #c8a1f466 111.96%);
  --gradient-section: linear-gradient(.13deg, #8c30f533 0.11%, #c8a1f433 111.96%);
  --gradient-accent: linear-gradient(.13deg, #fee05b33 0.11%, #c8a1f433 111.96%);
  --gradient-border: linear-gradient(90deg, #8c30f5 0%, #ff4b5c 100%);
  --icon-bg-wave: url("data:image/svg+xml,%3Csvg width='1440' height='740' viewBox='0 0 1440 740' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.03' d='M725.828 705.729C412.2 764.881 135.9 735.691 0 705.729V0H1440V705.729C1345.85 683.917 1001.11 653.809 725.828 705.729Z' fill='%23781BFF'/%3E%3C/svg%3E");
  --icon-wave-reverse: url("data:image/svg+xml,%3Csvg width='1440' height='838' viewBox='0 0 1440 838' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.03' d='M714.172 28.5644C1033.07 -13.0934 1304.1 -5.77416 1440 28.5644V837.401H0V28.5644C94.147 53.5639 220.05 93.1122 714.172 28.5644Z' fill='%23781BFF'/%3E%3C/svg%3E%0A");
  --icon-egg: url("data:image/svg+xml,%3Csvg width='775' height='1116' viewBox='0 0 775 1116' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 300.5C0 300.5 698 -374.5 752.5 300.5C807 975.5 752.5 894 695 917.5C637.5 941 0 1351 0 917.5C0 484.001 0 300.5 0 300.5Z' fill='%23FBF8FF'/%3E%3C/svg%3E%0A");
  --icon-bubble: url("data:image/svg+xml,%3Csvg width='978' height='799' viewBox='0 0 978 799' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M978 583.687C978 583.687 97.1689 1067.11 28.3934 583.687C-40.382 100.263 28.3934 158.632 100.955 141.802C173.516 124.971 978 -168.664 978 141.802C978 452.267 978 583.687 978 583.687Z' fill='%23FBF8FF'/%3E%3C/svg%3E%0A");
  --icon-arrow: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.1378 0.666676C8.91228 0.665324 8.68871 0.710139 8.47992 0.798549C8.27113 0.886958 8.08123 1.01722 7.92111 1.18188L1.06647 8.28812C0.906694 8.45377 0.779951 8.65042 0.693479 8.86684C0.607007 9.08326 0.5625 9.31523 0.5625 9.54948C0.5625 9.78374 0.607007 10.0157 0.693479 10.2321C0.779951 10.4486 0.906694 10.6452 1.06647 10.8108C1.22625 10.9765 1.41594 11.1079 1.6247 11.1975C1.83346 11.2872 2.05721 11.3333 2.28317 11.3333C2.50913 11.3333 2.73288 11.2872 2.94164 11.1975C3.1504 11.1079 3.34009 10.9765 3.49987 10.8108L9.1378 4.93042L14.7929 10.5799C14.9505 10.7616 15.1426 10.9077 15.3572 11.0089C15.5718 11.1101 15.8042 11.1644 16.0399 11.1683C16.2756 11.1721 16.5096 11.1255 16.7271 11.0314C16.9447 10.9373 17.1412 10.7976 17.3043 10.6211C17.4674 10.4447 17.5937 10.2352 17.6751 10.0059C17.7566 9.77654 17.7916 9.53224 17.7778 9.28825C17.764 9.04427 17.7019 8.80585 17.5952 8.58791C17.4885 8.36997 17.3396 8.17719 17.1577 8.02164L10.3031 1.16411C9.98971 0.850878 9.57308 0.673029 9.1378 0.666676Z' fill='%23781BFF'/%3E%3C/svg%3E%0A");
}

.checkbox__field:checked + .checkbox__content:after {
  opacity: 1;
}

.checkbox__field:focus + .checkbox__content:before {
  outline: 1px solid var(--color-gray-100);
  outline-offset: 1px;
}

.checkbox__field:disabled + .checkbox__content {
  opacity: .4;
  pointer-events: none;
}

html, body {
  scroll-behavior: smooth;
  overscroll-behavior-y: none;
}

.page {
  height: var(--vh);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-400);
  color: var(--color-gray-700);
  background-color: var(--color-white);
  font-optical-sizing: auto;
  font-size: clamp(16px, 1vw, 20px);
  font-style: normal;
  line-height: 1.5;
}

.page__body {
  min-width: 320px;
  min-height: var(--vh);
}

.page.open {
  overflow: hidden;
}

section {
  padding: 8.125rem 0;
}

@media only screen and (max-width: 992px) {
  section {
    padding: 2.75rem 0;
  }
}

.container {
  padding: 0 var(--container-offset);
  width: 100%;
  max-width: var(--container-width);
  margin: 0 auto;
}

.site-container {
  min-height: var(--vh);
  grid-template-rows: 1fr auto;
  grid-template-columns: 100%;
  display: grid;
}

.main {
  padding-top: var(--header-height);
}

#form-request {
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  margin: auto;
  transition-property: opacity, visibility;
  transition-duration: .2s;
  position: fixed;
  inset: 0;
}

#form-request:before {
  content: "";
  background-color: var(--color-black);
  opacity: .3;
  position: absolute;
  inset: 0;
}

#form-request iframe {
  z-index: 1;
  width: 90%;
  height: calc(var(--vh)  - 60px);
  max-width: 980px;
  border: none;
  margin: 30px auto;
  position: absolute;
  left: 0;
  right: 0;
}

#form-request.open {
  opacity: 1;
  visibility: visible;
}

.overlay {
  position: relative;
}

.overlay:before {
  content: "";
  z-index: -1;
  background: var(--gradient-main);
  position: absolute;
  inset: 0;
}

.hidden {
  display: none !important;
}

.sr-only {
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  width: 1px !important;
  height: 1px !important;
  white-space: nowrap !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  border: 0 !important;
  margin: -1px !important;
  padding: 0 !important;
  position: absolute !important;
  overflow: hidden !important;
}

.dis-scroll {
  width: 100%;
  height: 100dvh;
  height: calc(var(--vh, 1vh) * 100);
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

h1, h2, h3 {
  font-family: var(--font-family-primary);
  overflow: hidden;
}

h1, h2 {
  font-weight: var(--fw-600);
  line-height: 1.21;
}

h1 {
  color: var(--color-purple-800);
  letter-spacing: -.5px;
  margin-bottom: 24px;
  font-size: clamp(36px, 3rem + 1vi, 50px);
}

@media only screen and (max-width: 992px) {
  h1 {
    letter-spacing: -1px;
    font-size: 34px;
  }
}

h2 {
  color: var(--color-purple-500);
  text-transform: capitalize;
  font-size: clamp(32px, 2rem + 1vi, 40px);
}

@media only screen and (max-width: 992px) {
  h2 {
    font-size: 32px;
  }
}

h3 {
  font-size: 20px;
  line-height: 1.3;
  font-weight: var(--fw-700);
  color: var(--color-blue-700);
}

p {
  font-family: var(--font-family-primary);
  font-size: clamp(20px, 1rem + 1vi, 28px);
  line-height: 1.21;
  font-weight: var(--fw-400);
  color: var(--color-blue-770);
}

.header {
  z-index: 10;
  width: 100%;
  background-color: var(--color-purple-400);
  padding: 20px 0;
  transition-property: transform, padding;
  transition-duration: .15s;
  position: fixed;
  top: 0;
}

.header.hide {
  transform: translateY(-100%);
}

.header .container {
  max-width: 1394px;
}

.nav {
  justify-content: space-between;
  align-items: center;
  display: flex;
  position: relative;
}

.nav .logo {
  max-width: 298px;
  width: 100%;
}

@media only screen and (max-width: 374px) {
  .nav .logo {
    max-width: 240px;
  }

  .nav .logo img {
    object-fit: contain;
  }
}

.nav__inner {
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__inner {
    left: 0;
    top: calc(var(--header-height) * 1);
    z-index: -1;
    width: 100%;
    height: calc(var(--vh));
    background-color: var(--color-purple-400);
    flex-direction: column;
    justify-content: flex-start;
    padding: 30px 16px;
    transition-property: transform;
    transition-duration: .4s;
    position: fixed;
    overflow-y: auto;
    transform: translateX(150%);
  }
}

.nav__list {
  align-items: center;
  margin: 0 106.5px 0 0;
  display: flex;
}

@media only screen and (max-width: 1330px) {
  .nav__list {
    margin: 0 30px 0 0;
  }
}

@media only screen and (max-width: 1180px) {
  .nav__list {
    z-index: -1;
    flex-direction: column;
    margin-right: 0;
  }
}

.nav__item:not(:last-of-type) {
  margin-right: 1.25rem;
}

@media only screen and (max-width: 1330px) {
  .nav__item:not(:last-of-type) {
    margin-right: .9375rem;
  }
}

@media only screen and (max-width: 1180px) {
  .nav__item:not(:last-of-type) {
    margin-right: 0;
  }

  .nav__item {
    margin-bottom: 30px;
  }
}

.nav__link {
  font-size: clamp(18px, .4rem + 1vi, 20px);
  line-height: 1.21;
  font-weight: var(--fw-500);
  color: var(--color-white);
  text-transform: capitalize;
  padding: 4px;
  transition: border-bottom .3s ease-in-out;
}

.nav__link.true, .nav__link:hover {
  border-bottom: 2px solid var(--color-white);
}

.nav__btns {
  align-items: center;
  gap: 20px;
  display: flex;
}

@media only screen and (max-width: 1180px) {
  .nav__btns {
    flex-direction: column;
    gap: 30px;
  }
}

[class].btn {
  border: 1px solid var(--color-white);
  font-family: var(--font-family-primary);
  font-weight: var(--fw-500);
  color: var(--color-black);
  text-align: center;
  background-color: var(--color-white);
  border-radius: 0;
  margin: 0;
  padding: 8px 20px;
  font-size: clamp(18px, 1rem + 1vi, 20px);
  line-height: 1.21;
  transition-property: background-color, color, border-color;
  transition-duration: .4s;
  transition-timing-function: ease-in-out;
  display: inline-block;
}

[class].btn:hover {
  background-color: var(--color-purple-200);
  border-color: var(--color-black);
}

[class].btn--reverse {
  color: var(--color-white);
  background-color: var(--color-red-400);
  border-color: var(--color-red-400);
}

[class].btn--reverse:hover {
  background-color: var(--color-red-600);
  border-color: var(--color-red-600);
}

[class].btn--large {
  padding: 20px 32px;
  font-size: clamp(20px, 1rem + 1vi, 22px);
}

@media only screen and (max-width: 992px) {
  [class].btn--large {
    padding: 16px 20px;
  }
}

[class].btn--accent {
  color: var(--color-red-400);
  border-radius: var(--radius-xs);
  border-color: var(--color-red-400);
  padding: 12px 31px;
  font-size: 16px;
  line-height: 1.5;
}

[class].btn--accent:hover {
  background-color: var(--color-red-600);
  border-color: var(--color-red-600);
  color: var(--color-white);
}

[class].btn--send {
  max-width: -moz-fit-content;
  max-width: fit-content;
  color: var(--color-white);
  background-color: var(--color-dark);
  border-radius: var(--radius-s);
  padding: 15px 22px;
  font-size: 18px;
  line-height: 1;
}

[class].btn--send:hover {
  background-color: var(--color-gray-600);
  border-color: var(--color-gray-600);
  color: var(--color-milk-100);
}

[class].btn--mobile {
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  display: none;
}

@media only screen and (max-width: 992px) {
  [class].btn--mobile {
    display: block;
  }
}

[class].swiper-button {
  width: 167px;
  height: 30px;
  top: calc(50% + 10px);
  right: 105px;
}

[class].swiper-button:after {
  content: "";
  background-image: url("../images/icons/arrow-next.svg");
  background-position: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 1180px) {
  [class].swiper-button {
    width: 60px;
    right: 0;
  }
}

@media only screen and (max-width: 374px) {
  [class].swiper-button {
    width: 40px;
  }
}

.burger {
  --line-height: 2px;
  --buger-size: 20px;
  --move: calc(var(--buger-size) / 3);
  --move-decrement: calc(var(--move) * -1);
  --transition-time: .5s;
  --lines-color: var(--color-black);
  width: calc(var(--buger-size)  + 5px);
  height: var(--buger-size);
  transition: opacity var(--transition-time) ease;
  background-color: #0000;
  display: none;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.burger span {
  z-index: -1;
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  transition: transform var(--transition-time) ease, background-color var(--transition-time) ease .3s;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.burger span:before, .burger span:after {
  content: "";
  width: 100%;
  height: var(--line-height);
  background-color: var(--lines-color);
  animation-duration: .4s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
  position: absolute;
  left: 0;
}

.burger span:before {
  transform: translateY(var(--move-decrement)) rotate(0);
}

.burger span:after {
  transform: translateY(var(--move)) rotate(0);
}

.burger:hover {
  opacity: 1;
}

@media only screen and (max-width: 1180px) {
  .burger {
    display: block;
  }
}

.closed.opened span {
  background-color: var(--lines-color);
  transition: background-color .2s;
}

.closed.opened span:before {
  animation-name: toplineburger;
}

.closed.opened span:after {
  animation-name: bottomlineburger;
}

.opened .nav__inner {
  transform: translateX(0);
}

.opened span {
  background-color: #0000;
  transition: background-color .2s;
}

.opened span:before, .opened span:after {
  animation-duration: .2s;
  animation-timing-function: cubic-bezier(.645, .045, .355, 1);
  animation-fill-mode: forwards;
}

.opened span:before {
  animation-name: toplinecross;
}

.opened span:after {
  animation-name: bottomlinecross;
}

.hero {
  padding: 0;
}

.hero .overlay:not(.hero--home .hero__inner):before {
  opacity: 0;
}

@media only screen and (max-width: 992px) {
  .hero .overlay:not(.hero--home .hero__inner):before {
    opacity: 1;
  }
}

.hero__inner {
  min-height: 727px;
  background-position: 86% -45px;
  background-repeat: no-repeat;
  flex-direction: column;
  padding: 5rem 0;
  display: flex;
  overflow: hidden;
}

.hero__content {
  z-index: 1;
  max-width: 630px;
  width: 100%;
  margin-inline: 6.25rem 0;
}

.hero__desc {
  max-width: 437px;
  width: 100%;
  color: var(--color-black);
  margin-bottom: 2.5rem;
}

.hero--home .hero__desc {
  color: var(--color-white);
}

.hero--about .hero__inner {
  background-position: 81% 80px;
}

.hero--services .hero__inner {
  min-height: 661px;
  background-position: 84% 80px;
}

.hero--works .hero__inner {
  background-position: 89% 80px;
}

.hero--works .hero__desc {
  max-width: 100%;
}

.hero--security .hero__inner {
  min-height: 868px;
  background-position: 100% 48px;
  padding-top: 8.125rem;
}

.hero--security .hero__content {
  max-width: 828px;
  margin-inline: 3.125rem 0;
}

.hero--security h2 {
  margin-bottom: 20px;
}

.hero--security .hero__desc {
  max-width: 100%;
  margin-bottom: 20px;
}

.hero--security .hero__item {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: calc(var(--radius-m) * 5);
  padding: 1.75rem 2.75rem;
}

.hero--security .hero__item:not(:last-of-type) {
  margin-bottom: 20px;
}

.hero--security .hero__item:first-child {
  background-color: var(--color-purple-691);
}

.hero--security .hero__item:nth-child(2) {
  background-color: var(--color-red-391);
}

.hero--security .hero__item:last-child {
  background-color: var(--color-purple-391);
}

.hero--security .hero__item p {
  color: var(--color-white);
}

@media only screen and (max-width: 1280px) {
  .hero--security .hero__inner {
    min-height: 450px;
    background-position: 100%;
    background-size: 40%;
    padding: 44px 0;
  }

  .hero--security .hero__content {
    width: 70%;
    padding: 0;
  }
}

@media only screen and (max-width: 992px) {
  .hero--security .hero__content {
    margin: 0;
  }

  .hero--security .hero__item {
    padding: 15px 10px;
  }

  .hero--security .hero__item p {
    letter-spacing: -1px;
    font-size: 18px;
  }
}

@media only screen and (max-width: 576px) {
  .hero--security .hero__content {
    width: 100%;
  }
}

@media only screen and (max-width: 1180px) {
  .hero--about .hero__inner, .hero--services .hero__inner, .hero--works .hero__inner {
    background-position: 100%;
    background-size: 40%;
  }
}

@media only screen and (max-width: 992px) {
  .hero--about .hero__inner, .hero--services .hero__inner, .hero--works .hero__inner {
    min-height: 450px;
  }
}

@media only screen and (max-width: 1180px) {
  .hero--about .hero__content, .hero--services .hero__content, .hero--works .hero__content {
    margin: 0;
    padding: 0 15px;
  }
}

@media only screen and (max-width: 992px) {
  .hero--about .hero__desc, .hero--services .hero__desc, .hero--works .hero__desc {
    color: var(--color-white);
  }

  .hero__inner {
    min-height: 450px;
    padding: 44px 0;
    position: relative;
    background-image: none !important;
  }

  .hero__inner:after {
    content: "";
    width: 400px;
    height: 225px;
    z-index: -1;
    background-image: url("../images/hero/home.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .hero__content {
    margin: 0 auto;
    padding: 0 15px;
  }

  .hero h1 {
    width: 80%;
  }

  .hero__desc {
    margin-bottom: 20px;
  }

  .hero--about .hero__inner:after {
    width: 280px;
    height: 280px;
    background-image: url("../images/hero/about.png");
  }

  .hero--services .hero__inner:after {
    width: 300px;
    height: 280px;
    background-image: url("../images/hero/services.png");
  }

  .hero--works .hero__inner:after {
    width: 300px;
    height: 240px;
    background-image: url("../images/hero/works.png");
  }

  .hero--security .hero__inner:after {
    width: 260px;
    height: 240px;
    background-image: url("../images/works/security.png");
  }
}

@media only screen and (max-width: 576px) {
  .hero h1 {
    width: 100%;
  }

  .hero__inner {
    padding: 30px 0 9.375rem;
  }

  .hero__inner:after {
    width: 250px;
    height: 150px;
  }

  .hero--about .hero__inner:after {
    width: 220px;
    height: 220px;
  }

  .hero--services .hero__inner:after {
    width: 240px;
    height: 220px;
  }

  .hero--works .hero__inner:after {
    width: 200px;
    height: 170px;
  }

  .hero--security .hero__inner:after {
    width: 180px;
    height: 200px;
    bottom: 70px;
  }
}

.services {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 10.25rem 0 16px;
}

.services__nav {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.5rem;
  display: flex;
}

.services__desc {
  color: var(--color-blue-770);
  margin-bottom: 5.875rem;
}

.services__grid {
  max-width: 1149px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  place-items: stretch stretch;
  gap: 1rem;
  display: grid;
}

.services__item {
  padding: 10px 10px 10px 7.6875rem;
  position: relative;
}

.services__item:before {
  content: "";
  width: 86px;
  height: 86px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 10px;
  left: 10px;
}

.services__item--bookkeeping:before {
  background-image: url("../images/icons/bookkeeping-icon.svg");
}

.services__item--reporting:before {
  background-image: url("../images/icons/reporting-icon.svg");
}

.services__item--tax:before {
  background-image: url("../images/icons/tax-icon.svg");
}

.services__item--payroll:before {
  background-image: url("../images/icons/payroll-icon.svg");
}

.services__item--auditing:before {
  background-image: url("../images/icons/auditing-icon.svg");
}

.services__item--analysis:before {
  background-image: url("../images/icons/analysis-icon.svg");
}

.services__name {
  max-width: 164px;
  width: 100%;
}

.services__text {
  font-size: 16px;
  line-height: 1.61;
}

.services--spectrum {
  max-width: unset;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--icon-bg-wave);
  padding: 4.5625rem 0 20px;
}

.services--spectrum h2 {
  text-align: center;
  letter-spacing: -1px;
  margin-bottom: 8rem;
}

@media only screen and (max-width: 992px) {
  .services--spectrum h2 {
    margin-bottom: 30px;
  }
}

.services--spectrum .services__grid {
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .services {
    padding: 4.25rem 0 2.75rem;
  }

  .services__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;
    justify-items: center;
    margin-bottom: 30px;
  }

  .services__nav .btn {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .services__nav, .services__desc {
    margin-bottom: 30px;
  }

  .services__nav {
    flex-direction: column;
    align-items: flex-start;
  }

  .services__grid {
    grid-template-columns: 1fr;
    margin-bottom: 30px;
  }

  .services__item {
    padding: 10px 10px 10px 80px;
  }

  .services__item:before {
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
  }

  .services__name {
    max-width: 100%;
  }
}

@media only screen and (max-width: 475px) {
  .services__item {
    padding: 60px 10px 10px;
  }

  .services__item:before {
    top: 0;
    transform: translateY(0);
  }
}

.about {
  padding-block: 8.125rem 3.625rem;
  position: relative;
}

.about:before {
  content: "";
  z-index: -1;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--icon-bg-wave);
  position: absolute;
  inset: 0;
}

.about__inner {
  max-width: 1136px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
  display: flex;
}

.about__box {
  max-width: 446px;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  display: flex;
}

.about__content p {
  color: var(--color-blue-770);
  margin-bottom: 0;
}

.about__content p:not(:last-of-type) {
  margin-bottom: 4.75rem;
}

.about__img {
  border-radius: var(--radius-main);
  z-index: -1;
  position: absolute;
  top: -52px;
  right: 0;
}

.about__phrase {
  color: var(--color-purple-500);
  margin-bottom: 8px;
  font-size: 22px;
  line-height: 1.18;
}

.about__cards {
  max-width: 708px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  place-items: stretch stretch;
  gap: 3.75rem;
  display: grid;
}

.about__card {
  background-color: var(--color-white);
  border-radius: calc(var(--radius-m) * 2);
  padding: 1.25rem;
}

.about__card h3 {
  margin-bottom: 4px;
  font-size: 20px;
  line-height: 1.3;
}

.about__card p {
  font-size: 16px;
  line-height: 1.62;
}

@media only screen and (max-width: 1180px) {
  .about--home .about__image {
    position: relative;
  }
}

.about--history {
  padding: 4.375rem 0;
}

.about--history .about__inner {
  align-items: center;
  padding: 0 0 0 24px;
}

@media only screen and (max-width: 1180px) {
  .about--history .about__inner {
    padding: 0;
  }
}

@media only screen and (max-width: 768px) {
  .about--history .about__inner {
    flex-direction: column;
  }
}

.about--history .about__content p {
  font-size: clamp(20px, 1rem + 1vi, 23px);
}

.about--history .about__content p:not(:last-of-type) {
  margin-bottom: 20px;
}

.about--history img {
  object-fit: contain;
  object-position: center 70%;
}

.about--approach {
  padding: 9.3125rem 0 6.4375rem;
}

.about--approach:before {
  background-image: var(--icon-wave-reverse);
}

.about--approach .about__inner {
  max-width: 1230px;
  gap: 2.5rem;
}

.about--approach .about__box {
  max-width: 476px;
  gap: 0;
}

.about--approach h2 {
  margin-bottom: 26px;
}

.about--approach .about__content p {
  width: 87%;
  font-size: 23px;
}

@media only screen and (max-width: 1280px) {
  .about--approach .about__inner, .about--approach .about__cards {
    gap: 30px;
  }

  .about--approach .about__inner {
    flex-direction: column;
  }

  .about--approach .about__phrase {
    order: 1;
  }

  .about--approach .about__content {
    order: 2;
  }

  .about--approach .about__box, .about--approach .about__cards {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .about--approach .about__content p {
    width: 100%;
  }

  .about--approach .about__cards {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.about--service {
  padding: 8.125rem 0;
}

.about--service:before {
  background-image: none;
}

.about--service .about__inner {
  justify-content: flex-start;
  gap: 0 5.125rem;
}

.about--service .about__box {
  max-width: 492px;
}

@media only screen and (max-width: 992px) {
  .about--service .about__box {
    max-width: 100%;
  }
}

.about--service .about__phrase {
  margin-bottom: 0;
}

.about--service h2 {
  color: var(--color-blue-700);
  font-size: clamp(34px, 2.5rem + 1vi, 48px);
}

@media only screen and (max-width: 992px) {
  .about--service h2 {
    font-size: 32px;
  }
}

.about--service .about__content p {
  font-size: 23px;
}

@media only screen and (max-width: 1280px) {
  .about--service .about__inner {
    gap: 30px;
  }
}

.about--bookkeeping .about__content {
  width: 87%;
}

.about--bookkeeping .about__image {
  min-height: 444px;
}

.about--bookkeeping .about__image img {
  position: absolute;
  top: 72px;
  right: 25px;
}

@media only screen and (max-width: 1280px) {
  .about--bookkeeping .about__image img {
    position: static;
  }

  .about--bookkeeping .about__box {
    max-width: 100%;
  }

  .about--bookkeeping .about__image {
    min-height: auto;
  }
}

@media only screen and (max-width: 768px) {
  .about--bookkeeping .about__inner {
    flex-direction: column;
  }

  .about--bookkeeping .about__content {
    width: 100%;
  }
}

.about--reporting .about__inner, .about--payroll .about__inner, .about--auditing .about__inner {
  gap: 0 6.25rem;
}

@media only screen and (max-width: 1280px) {
  .about--reporting .about__inner, .about--payroll .about__inner, .about--auditing .about__inner {
    gap: 30px;
  }
}

.about--tax:before {
  background-image: var(--icon-egg);
  width: 775px;
  height: 1115px;
  top: 0;
  left: 0;
  transform: translateY(-25%);
}

@media only screen and (max-width: 992px) {
  .about--tax:before {
    width: 100%;
    height: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .about--tax .about__inner {
    flex-direction: column;
  }
}

.about--auditing:before {
  background-image: var(--icon-bubble);
  width: 978px;
  height: 799px;
  top: 0;
  left: unset;
  right: 0;
  transform: translateY(-44%);
}

.about--auditing .about__content {
  width: 87%;
}

@media only screen and (max-width: 768px) {
  .about--auditing .about__inner {
    flex-direction: column;
  }

  .about--auditing .about__content {
    width: 100%;
  }
}

.about--financial .about__inner {
  gap: 0 3.75rem;
}

@media only screen and (max-width: 1280px) {
  .about--financial .about__inner {
    gap: 30px;
  }

  .about--financial img {
    object-fit: contain;
  }
}

.about--benefits {
  padding: 8.125rem 0;
}

.about--benefits:before {
  background-image: none;
}

.about--benefits h2 {
  max-width: 1136px;
  width: 100%;
  color: var(--color-purple-500);
  margin: 0 auto 10px;
  font-size: clamp(32px, 2rem + 1vi, 40px);
}

.about--benefits .about__inner {
  align-items: center;
  gap: 0 5rem;
}

.about--benefits .about__box {
  max-width: 565px;
}

.about--benefits .about__content p {
  font-size: clamp(18px, 2rem + 1vi, 28px);
}

.about--benefits .about__content p:not(:last-of-type) {
  margin-bottom: 25px;
}

.about--benefits .about__content b {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 992px) {
  .about--benefits h2 {
    margin-bottom: 30px;
  }

  .about--benefits .about__inner {
    flex-direction: column-reverse;
    gap: 30px;
  }

  .about--benefits .about__box {
    max-width: 100%;
  }

  .about--benefits .about__image, .about--benefits img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 4 / 2;
  }
}

@media only screen and (max-width: 1180px) {
  .about__inner {
    align-items: center;
    gap: 20px;
  }

  .about__img {
    filter: opacity(.5) blur(1px) brightness(.5);
    inset: 0;
  }

  .about img {
    height: auto;
  }
}

@media only screen and (max-width: 992px) {
  .about {
    padding: 2.75rem 0;
  }

  .about__content p:not(:last-of-type) {
    margin-bottom: 30px;
  }

  .about--service:not(.about--benefits) {
    padding: 44px 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .about__inner {
    flex-direction: column-reverse;
  }

  .about__box {
    max-width: 100%;
  }

  .about__image, .about img {
    width: 100%;
  }

  .about img {
    height: unset;
    aspect-ratio: 4 / 3;
  }
}

.reviews__items {
  grid-template-columns: repeat(3, 1fr);
  align-items: stretch;
  gap: 0 21px;
  display: grid;
}

.reviews__item {
  background-color: var(--color-white);
  border-radius: var(--radius-m);
  box-shadow: var(--shadow-main);
  padding-block: 5.375rem 1.875rem;
  padding-inline: 1.875rem;
  position: relative;
}

.reviews__avatar {
  border-radius: var(--radius-round);
  background: var(--gradient-border);
  padding: 6px;
  position: absolute;
  top: -35px;
  overflow: hidden;
}

.reviews__avatar img {
  border-radius: inherit;
}

.reviews__info {
  grid-template-columns: repeat(2, auto);
  margin-bottom: 20px;
  display: grid;
}

.reviews__position {
  grid-area: 2 / 1;
  font-size: 16px;
  line-height: 1.6;
}

.reviews__rating {
  justify-self: flex-end;
}

.reviews__txt {
  font-size: 16px;
  font-weight: var(--fw-600);
  color: var(--color-blue-760);
  line-height: 1.5;
}

.reviews--home {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: auto;
  padding: 14.5rem 0 8.125rem;
}

.reviews--home h2 {
  text-align: right;
  margin-bottom: 8.4375rem;
  margin-right: 14.5rem;
}

@media only screen and (max-width: 1180px) {
  .reviews--home h2 {
    margin-right: 0;
  }
}

@media only screen and (max-width: 992px) {
  .reviews--home {
    padding: 44px 0;
    background-image: none !important;
  }

  .reviews--home h2 {
    text-align: center;
    margin-bottom: 30px;
  }
}

.reviews--services {
  padding: 8.125rem 0 6.25rem;
}

.reviews--services .reviews__inner {
  max-width: 1136px;
  width: 100%;
  margin: 0 auto 14.375rem;
}

@media only screen and (max-width: 1180px) {
  .reviews--services .reviews__inner {
    margin: 0 auto 60px;
  }
}

.reviews--services h2 {
  margin-bottom: 3.125rem;
}

.reviews--services .reviews__items {
  gap: 0 6.25rem;
}

@media only screen and (max-width: 1180px) {
  .reviews--services .reviews__items {
    gap: 30px;
  }
}

.reviews--services .reviews__item {
  box-shadow: none;
  background-color: #0000;
  padding: 0;
}

.reviews--services .reviews__position {
  grid-column: 1 / span 2;
}

@media only screen and (max-width: 1180px) {
  .reviews--services .reviews__position {
    grid-column: auto;
  }
}

.reviews--services .reviews__accent {
  max-width: 680px;
  width: 100%;
  font-size: clamp(32px, 2.5rem + 1vi, 48px);
  font-weight: var(--fw-500);
  color: var(--color-purple-500);
  text-align: center;
  margin: 0 auto;
}

@media only screen and (max-width: 576px) {
  .reviews--services .reviews__accent {
    max-width: 100%;
    font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .reviews--services {
    padding: 44px 0;
  }
}

@media only screen and (max-width: 1180px) {
  .reviews__item {
    padding: 80px 10px 20px;
  }

  .reviews__info {
    grid-template-columns: 1fr;
  }

  .reviews__rating {
    grid-row: 1;
    justify-self: flex-start;
    margin-bottom: 10px;
  }

  .reviews br {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .reviews__items {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    gap: 30px;
  }

  .reviews__item {
    padding: 30px 10px 20px;
  }

  .reviews__item:last-of-type {
    width: 50%;
    grid-column: 1 / span 2;
  }

  .reviews__avatar {
    top: 10px;
    right: 10px;
  }
}

@media only screen and (max-width: 768px) {
  .reviews__items {
    grid-template-columns: 1fr;
  }

  .reviews__item:last-of-type {
    width: 100%;
    grid-column: auto;
  }

  .reviews__avatar {
    width: 70px;
    height: 70px;
  }

  .reviews__avatar img {
    width: 60px;
    height: 60px;
  }

  .reviews__info {
    padding-right: 80px;
  }
}

.explain {
  padding: 6.625rem 0 4.125rem;
  position: relative;
}

.explain:before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--icon-wave-reverse);
  z-index: -1;
  position: absolute;
  inset: 0;
}

.explain__inner {
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  margin-left: 6.25rem;
  display: flex;
}

.explain__info {
  margin-bottom: 5.625rem;
}

.explain h2 {
  margin-bottom: 3.4375rem;
}

.explain__items {
  max-width: 685px;
  width: 100%;
  counter-reset: index;
}

.explain__item {
  padding-left: 6.375rem;
  position: relative;
}

.explain__item:nth-child(1), .explain__item:nth-child(2) {
  padding-bottom: 18px;
}

.explain__item:nth-child(2) .explain__text, .explain__item:nth-child(3) .explain__text {
  width: 75%;
}

@media only screen and (max-width: 992px) {
  .explain__item:nth-child(2) .explain__text, .explain__item:nth-child(3) .explain__text {
    width: 100%;
  }
}

.explain__item:not(:last-of-type) {
  margin-bottom: 2.5625rem;
}

.explain__item:before {
  content: counter(index);
  counter-increment: index;
  width: 60px;
  height: 60px;
  font-size: clamp(18px, .5rem + 1vi, 20px);
  line-height: 60px;
  font-weight: var(--fw-700);
  text-align: center;
  color: var(--color-white);
  background-color: var(--color-purple-500);
  border-radius: var(--radius-round);
  position: absolute;
  top: 0;
  left: 0;
}

.explain__item:after {
  content: "";
  width: 2px;
  height: 70px;
  background: var(--gradient-border);
  opacity: .2;
  position: absolute;
  top: 68px;
  left: 30px;
}

.explain__name {
  font-size: clamp(20px, 1rem + 1vi, 24px);
  font-weight: var(--fw-600);
  margin-bottom: 10px;
  line-height: 1.1;
}

.explain__text {
  font-size: clamp(18px, 1rem + 1vi, 23px);
  font-weight: var(--fw-400);
  line-height: 1.2;
}

.explain--works h2 {
  width: 75%;
  letter-spacing: -.5px;
}

@media only screen and (max-width: 992px) {
  .explain--works h2 {
    width: 100%;
  }

  .explain {
    padding: 2.75rem 0;
  }

  .explain__inner {
    flex-direction: column;
    gap: 40px;
    margin: 0 auto;
  }

  .explain__info {
    width: 100%;
    margin-bottom: 0;
  }

  .explain__info .btn {
    display: none;
  }

  .explain h2 {
    margin-bottom: 0;
  }

  .explain__items {
    max-width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .explain__item {
    padding-left: 60px;
  }

  .explain__item:before {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }

  .explain__item:not(:last-of-type) {
    margin-bottom: 40px;
  }

  .explain__item:nth-child(1), .explain__item:nth-child(2) {
    padding-bottom: 0;
  }
}

.blog__inner {
  max-width: 1136px;
  width: 100%;
  margin: 0 auto;
}

.blog h2 {
  margin-bottom: 2rem;
}

.blog__recents {
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 0 57px;
  display: grid;
}

.blog__img {
  border-radius: var(--radius-main);
  margin-bottom: 2.875rem;
}

.blog__name, .blog__link {
  font-weight: var(--fw-400);
  line-height: 1.2;
}

.blog__name {
  color: var(--color-black);
  margin-bottom: 2.75rem;
  font-size: clamp(20px, 1rem + 1vi, 28px);
}

.blog__link {
  color: var(--color-purple-500);
  font-size: clamp(18px, 1rem + 1vi, 23px);
}

.blog--resources {
  padding: 4.5625rem 0 17.5rem;
}

.blog--resources .blog__inner {
  max-width: 1216px;
}

.blog--resources h1 {
  font-family: var(--font-family-secondary);
  letter-spacing: 0;
  margin-bottom: 20px;
  font-size: clamp(30px, 2rem + 1vi, 38px);
  line-height: 1.21;
}

.blog--resources .blog__intro {
  width: 65%;
  font-family: var(--font-family-secondary);
  color: var(--color-gray-700);
  margin-bottom: 3.75rem;
  font-size: clamp(20px, 1rem + 1vi, 26px);
}

.blog--resources .blog__recents {
  gap: 40px 57px;
}

.blog--resources .blog__article {
  position: relative;
}

.blog--resources .blog__img {
  margin-bottom: 44px;
}

.blog--resources .blog__name {
  color: var(--color-black);
  margin-bottom: 0;
  font-size: clamp(20px, 1rem + 1vi, 23px);
}

.blog--resources .blog__link {
  position: absolute;
  inset: 0;
}

@media only screen and (max-width: 992px) {
  .blog__recents {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    gap: 3.75rem;
  }

  .blog__recent {
    box-shadow: var(--shadow-main);
    border-radius: var(--radius-main);
    padding: 0 0 60px;
  }

  .blog__img {
    width: 100%;
    height: auto;
    aspect-ratio: 6 / 3;
    border-radius: var(--radius-main) var(--radius-main) 0 0;
    margin-bottom: 20px;
  }

  .blog__name, .blog__link {
    padding: 0 20px;
  }

  .blog--resources {
    padding: 44px 0;
  }

  .blog--resources .blog__intro {
    width: 100%;
    margin-bottom: 30px;
  }

  .blog--resources .blog__recents {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .blog--resources .blog__recent {
    border-radius: var(--radius-m);
  }

  .blog--resources .blog__img {
    border-radius: var(--radius-m) var(--radius-m) 0 0;
  }
}

@media only screen and (max-width: 768px) {
  .blog--resources .blog__recents {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 576px) {
  .blog__recent {
    padding: 0 0 30px;
  }
}

@keyframes toplinecross {
  0% {
    transform: translateY(var(--move-decrement));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: rotate(45deg);
  }
}

@keyframes bottomlinecross {
  0% {
    transform: translateY(var(--move));
  }

  50% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0)rotate(-45deg);
  }
}

@keyframes toplineburger {
  0% {
    transform: rotate(45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move-decrement)) rotate(0);
  }
}

@keyframes bottomlineburger {
  0% {
    transform: translateY(0)rotate(-45deg);
  }

  50% {
    transform: rotate(0);
  }

  100% {
    transform: translateY(var(--move)) rotate(0);
  }
}

.request {
  background: var(--gradient-secondary);
  padding: 5.375rem 0;
}

.request h2 {
  text-align: center;
  color: var(--color-black);
  letter-spacing: -1px;
  margin-bottom: 3rem;
}

.request__form {
  max-width: 800px;
  width: 100%;
  background-color: var(--color-white);
  border-radius: var(--radius-s);
  box-shadow: var(--shadow-main);
  flex-direction: column;
  margin: 0 auto;
  padding: 2.125rem 2rem;
  display: flex;
}

.request label:not(:last-of-type) {
  margin-bottom: 16px;
}

.request label:last-of-type {
  margin-bottom: 18px;
}

.request input, .request textarea {
  width: 100%;
  font-family: var(--font-family-secondary);
  font-size: 16px;
  line-height: 1;
  font-weight: var(--fw-400);
  color: var(--color-blue-700);
  background-color: var(--color-gray-400);
  border-radius: var(--radius-s);
  border: none;
  padding: 14px 16px;
  display: inline-block;
}

.request input::placeholder, .request textarea::placeholder {
  color: var(--color-gray-700);
  opacity: .6;
}

.request textarea {
  min-height: 9.75rem;
  resize: none;
  padding: 16px;
}

@media only screen and (max-width: 992px) {
  .request {
    padding: 2.75rem 0;
  }

  .request h2 {
    margin-bottom: 30px;
  }

  .request__form {
    padding: 20px 15px;
  }

  .request .btn {
    max-width: 100%;
  }
}

.article {
  padding: 4.3125rem 0 9.3125rem;
}

.article h1 {
  color: var(--color-dark);
  margin-bottom: 23px;
  font-size: clamp(32px, 2rem + 1vi, 40px);
  line-height: 1.21;
}

.article__date {
  color: var(--color-gray-700);
  margin-bottom: 40px;
  font-size: clamp(18px, 1rem + 1vi, 23px);
  line-height: 1.2;
}

.article__image {
  border-radius: calc(var(--radius-m) * 5);
  object-position: center 35%;
  margin-bottom: 40px;
}

.article__text {
  color: var(--color-black);
}

.article__text:not(:last-of-type) {
  margin-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  .article {
    padding: 44px 0;
  }

  .article__image {
    height: auto;
    border-radius: var(--radius-m);
    margin-bottom: 20px;
  }

  .article__text:not(:last-of-type) {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .article h1 {
    margin-bottom: 10px;
    font-size: 32px;
  }

  .article__image {
    aspect-ratio: 3 / 2;
  }

  .article__text {
    font-size: 18px;
  }
}

.policy {
  background: var(--gradient-secondary);
  padding: 5.375rem 0;
}

.policy h1 {
  color: var(--color-black);
  text-align: center;
  margin-bottom: 10px;
}

.policy .container {
  background-color: var(--color-white);
  border-radius: var(--radius-secondary);
  padding: 3.75rem;
}

.policy h2 {
  color: var(--color-black);
  text-transform: capitalize;
  margin-bottom: 20px;
  font-size: clamp(20px, 1rem + 1vi, 28px);
}

.policy p, .policy a, .policy li {
  color: var(--color-gray-700);
  font-size: clamp(20px, 1rem + 1vi, 23px);
}

.policy p:not(:last-child) {
  margin-bottom: 3rem;
}

.policy a {
  color: var(--color-purple-800);
  text-decoration: underline;
}

.policy ul {
  margin-bottom: 30px;
  padding-left: 15px;
  font-weight: 500;
}

.policy li {
  list-style-type: disc;
}

.policy li:not(:last-child) {
  margin-bottom: 12px;
}

@media only screen and (max-width: 992px) {
  .policy {
    padding: 44px 0;
  }

  .policy .container {
    border-radius: calc(var(--radius-m) * 2);
    padding: 40px 15px;
  }

  .policy p:not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .policy ul {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .policy p, .policy a, .policy li {
    font-size: 18px;
  }
}

.footer {
  background-color: var(--color-dark);
  padding: 5.25rem 0;
}

.footer * {
  color: var(--color-white);
}

.footer__inner {
  max-width: 1246px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
}

@media only screen and (max-width: 768px) {
  .footer__inner {
    text-align: center;
    flex-direction: column-reverse;
    gap: 30px;
  }
}

.footer__nav {
  align-items: center;
  padding-right: 4px;
  display: flex;
}

.footer__item:not(:last-child) {
  margin-right: 3rem;
}

.footer__link, .footer .copyright {
  font-size: 16px;
  line-height: 1.21;
}

.footer__link {
  font-weight: var(--fw-400);
}

.footer .copyright {
  font-weight: var(--fw-600);
}

@media only screen and (max-width: 992px) {
  .footer {
    padding: 2.75rem 0;
  }
}

.key {
  background: var(--gradient-section);
  padding: 7.5rem 0 5.375rem;
}

.key__inner {
  max-width: 1216px;
  width: 100%;
  margin: 0 auto;
}

.key h2 {
  text-align: center;
  margin-bottom: 3.75rem;
}

.key__grid {
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 2.5rem;
  padding: 0 1.75rem;
  display: flex;
}

.key__item {
  flex-basis: 31%;
}

.key__year {
  font-size: clamp(36px, 3rem + 1vi, 50px);
  line-height: 1.44;
  font-weight: var(--fw-700);
  margin-bottom: 10px;
  padding-left: 86px;
  position: relative;
}

.key__year:before {
  content: "";
  width: 60px;
  height: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.key__year--inception:before {
  background-image: url("../images/icons/Inception-icon.svg");
}

.key__year--expansion:before {
  background-image: url("../images/icons/expansion-icon.svg");
}

.key__year--technology:before {
  background-image: url("../images/icons/technology-icon.svg");
}

.key__year--global:before {
  background-image: url("../images/icons/export-icon.svg");
}

.key__year--industry:before {
  background-image: url("../images/icons/industry-icon.svg");
}

.key__name {
  margin-bottom: 10px;
  font-size: 22px;
}

.key__desc {
  font-size: 23px;
}

@media only screen and (max-width: 1280px) {
  .key__grid {
    justify-content: flex-start;
    padding: 0;
  }

  .key__item {
    flex-basis: 45%;
  }

  .key__item:last-of-type {
    margin: 0 auto;
  }
}

@media only screen and (max-width: 992px) {
  .key {
    padding: 2.75rem 0;
  }

  .key h2 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 768px) {
  .key__item {
    flex: 100%;
  }

  .key__year {
    padding-left: 70px;
    font-size: clamp(36px, 3rem + 1vi, 38px);
  }

  .key__year:before {
    width: 50px;
    height: 50px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.team h2 {
  text-align: center;
  margin-bottom: 10px;
  line-height: 1;
}

.team__subtitle {
  font-size: clamp(20px, 1rem + 1vi, 23px);
  line-height: 1.2;
  font-weight: var(--fw-400);
  color: var(--color-blue-700);
  margin-bottom: 20px;
}

.team__list {
  grid-template-columns: repeat(3, 1fr);
  grid-auto-rows: 1fr;
  place-items: stretch stretch;
  gap: 13px;
  display: grid;
}

.team__item {
  border: 1px solid var(--color-purple-400);
  border-radius: calc(var(--radius-m)  + 2px);
  padding: 2.8125rem 2.4375rem;
}

.team__avatar {
  border-radius: var(--radius-round);
  margin: 0 auto 20px;
}

.team__bio {
  margin: 0 auto;
  padding: 10px;
}

.team__name {
  color: var(--color-black);
  margin-bottom: 20px;
  font-size: 22px;
  line-height: 1.2;
}

.team__text {
  font-size: 23px;
}

@media only screen and (max-width: 992px) {
  .team__list {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .team__item {
    justify-content: space-between;
    align-items: center;
    gap: 15px;
    padding: 20px 15px;
    display: flex;
  }

  .team__avatar {
    margin-bottom: 0;
  }

  .team__bio {
    padding: 0;
  }
}

@media only screen and (max-width: 576px) {
  .team__item {
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.clients {
  padding: 8.125rem 0;
}

.clients__inner {
  max-width: 1216px;
  width: 100%;
  margin: 0 auto;
}

.clients h2 {
  margin-bottom: 1.25rem;
}

.clients__desc {
  margin-bottom: 3.75rem;
  font-size: 23px;
}

.clients__wrapper {
  position: relative;
}

.clients__slider {
  max-width: 812px;
  width: 100%;
  margin: 0;
}

.clients__slides {
  align-items: stretch !important;
}

.clients__slide {
  background: var(--gradient-accent);
  border-radius: var(--radius-main);
  padding: 2rem 1.5rem 4.625rem;
}

.clients__slide h3 {
  font-size: clamp(20px, 1rem + 1vi, 28px);
  font-weight: var(--fw-600);
  margin-bottom: 4.6875rem;
}

@media only screen and (max-width: 992px) {
  .clients {
    padding: 44px 0;
  }

  .clients__slider {
    width: 80%;
  }

  .clients__slide h3 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 475px) {
  .clients__slide {
    padding: 2rem .625rem;
  }

  .clients__slide p {
    font-size: 18px;
  }
}

.pricing__inner {
  max-width: 1130px;
  width: 100%;
  margin: 0 auto;
}

.pricing h2 {
  margin-bottom: 2.5rem;
}

.pricing__grid {
  max-width: 1063px;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  align-items: stretch;
  gap: 10px;
  margin-bottom: 40px;
  display: grid;
}

.pricing__item {
  background: var(--gradient-border);
  border-radius: var(--radius-m);
  padding: 3px;
}

.pricing__wrapper {
  height: 100%;
  background-color: var(--color-white);
  border-radius: inherit;
  padding: 2.8125rem 3.5625rem;
}

.pricing__name {
  font-size: clamp(20px, 1rem + 1vi, 24px);
  line-height: 1.25;
  font-weight: var(--fw-600);
  text-align: center;
  margin-bottom: 20px;
}

.pricing__price {
  text-align: center;
  margin-bottom: 10px;
  font-size: clamp(56px, 3rem + 2vi, 72px);
  line-height: 1.1;
}

.pricing__time {
  width: 100%;
  color: #0000;
  text-align: center;
  background: var(--gradient-border);
  border-bottom: 1px solid var(--color-purple-510);
  -webkit-background-clip: text;
  background-clip: text;
  margin-bottom: 10px;
  padding-bottom: 20px;
  font-size: 16px;
  line-height: 1.62;
}

.pricing__details {
  padding: 10px;
}

.pricing__details p {
  color: var(--color-black);
  font-size: clamp(18px, 1rem + 1vi, 23px);
}

.pricing__txt {
  margin-bottom: 16px;
}

.pricing__detail {
  display: flex;
}

.pricing__detail:not(:last-of-type) {
  margin-bottom: 30px;
}

.pricing__detail span {
  padding-right: 10px;
}

.pricing__desc {
  width: 90%;
  font-size: clamp(18px, 1rem + 1vi, 23px);
}

@media only screen and (max-width: 992px) {
  .pricing {
    padding: 80px 0 44px;
  }

  .pricing__wrapper {
    padding: 20px 15px 40px;
  }

  .pricing__details {
    padding: 0;
  }

  .pricing__desc {
    width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .pricing__grid {
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
  }

  .pricing__detail:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

.solutions {
  background-image: var(--icon-wave-reverse);
  padding: 0 0 8.8125rem;
}

.solutions__inner {
  max-width: 1136px;
  width: 100%;
  margin: 0 auto;
}

.solutions h2 {
  text-align: right;
  margin-bottom: 3.75rem;
  padding-right: 6.875rem;
}

.solutions__items {
  max-width: 1095px;
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.8125rem;
  display: grid;
}

.solutions h3 {
  margin-bottom: 16px;
  font-size: clamp(20px, 1rem + 1vi, 22px);
  line-height: 1.25;
}

.solutions__content p {
  font-size: clamp(20px, 1rem + 1vi, 23px);
}

@media only screen and (max-width: 992px) {
  .solutions {
    padding: 44px 0;
  }

  .solutions__items {
    grid-template-columns: 1fr;
  }
}

@media only screen and (max-width: 768px) {
  .solutions h2 {
    text-align: center;
    padding-right: 0;
  }

  .solutions__items {
    gap: 30px;
  }
}

.options {
  padding: 4.25rem 0 5rem;
}

.options h2 {
  text-align: center;
  margin-bottom: 3rem;
}

.options__grid {
  grid-template-columns: repeat(2, 1fr);
  align-items: stretch;
  gap: 2.5rem;
  display: grid;
}

.options__item {
  border-radius: calc(var(--radius-m) * 2);
  padding: 20px;
  position: relative;
}

.options__item:before {
  content: "";
  height: 60px;
  width: 60px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 20px;
  right: 20px;
}

.options__item--tailored {
  background-color: var(--color-purple-100);
}

.options__item--tailored:before {
  background-image: url("../images/icons/packages-icon.svg");
}

.options__item--additional {
  background-color: var(--color-red-100);
}

.options__item--additional:before {
  background-image: url("../images/icons/integration-icon.svg");
  right: 36px;
}

.options__item--reporting {
  background-color: var(--color-red-120);
}

.options__item--reporting:before {
  background-image: url("../images/icons/specialized-icon.svg");
  top: 14px;
}

.options__item--reporting p {
  width: 90%;
}

.options__item--flexible {
  background-color: var(--color-blue-100);
}

.options__item--flexible:before {
  background-image: url("../images/icons/schedulign-icon.svg");
  right: 36px;
}

.options__item--tailored h3, .options__item--additional h3 {
  width: 65%;
}

@media only screen and (max-width: 992px) {
  .options__item--tailored h3, .options__item--additional h3 {
    width: 100%;
  }
}

.options h3 {
  font-size: clamp(20px, 1rem + 1vi, 28px);
  line-height: 1.1;
  font-weight: var(--fw-600);
  margin-bottom: 16px;
}

@media only screen and (max-width: 992px) {
  .options {
    padding: 44px 0;
  }

  .options__grid {
    grid-template-columns: 1fr;
  }

  .options__item {
    padding-right: 94px;
  }
}

@media only screen and (max-width: 576px) {
  .options__item {
    padding: 10px;
  }

  .options__item:before {
    height: 50px;
    width: 50px;
    top: 10px;
    right: 10px !important;
  }

  .options__item h3 {
    padding-bottom: 40px;
    padding-right: 60px;
  }

  .options__item p {
    width: 100%;
  }
}

.faq {
  padding: 6.25rem 0;
}

.faq h2 {
  text-align: center;
  margin-bottom: 20px;
}

.faq__item {
  border: 2px solid var(--color-purple-500);
  border-radius: var(--radius-m);
  padding: 0;
}

.faq__item:not(:last-child) {
  margin-bottom: 40px;
}

.faq details {
  overflow: hidden;
}

.faq details p {
  color: var(--color-black);
}

.faq details[open] summary:before {
  transform: rotate(0);
}

.faq summary {
  font-weight: var(--fw-600);
  color: var(--color-purple-500);
  cursor: pointer;
  padding: 24px 58px 25px;
  font-size: clamp(20px, 1rem + 1vi, 28px);
  position: relative;
}

.faq summary:before {
  content: "";
  width: 18px;
  height: 11px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: var(--icon-arrow);
  background-position: center;
  transition: transform .3s ease-in-out;
  position: absolute;
  top: 34px;
  right: 36px;
  overflow: hidden;
  transform: rotate(180deg);
}

.faq summary + div {
  padding: 0 50px 24px;
}

.faq a {
  margin: 0 auto;
}

@media only screen and (max-width: 992px) {
  .faq {
    padding: 44px 0;
  }

  .faq summary {
    padding: 24px 30px 24px 15px;
  }

  .faq summary + div {
    padding: 0 15px 24px;
  }

  .faq summary:before {
    top: 15px;
    right: 15px;
  }
}

@media only screen and (max-width: 576px) {
  .faq summary, .faq details p {
    font-size: 18px;
  }
}

/*# sourceMappingURL=main.css.map */

@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.nav {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-width: 298px;
    width: 100%;

    @include media(mobile-sm) {
      max-width: 240px;

      img {
        object-fit: contain;
      }
    }
  }

  &__inner {
    display: flex;
    justify-content: flex-end;
    width: 100%;

    @include media(tablet-wide) {
      position: fixed;
      left: 0;
      top: calc(var(--header-height) * 1);
      z-index: -1;
      flex-direction: column;
      justify-content: flex-start;
      overflow-y: auto;
      padding: 30px 16px;
      width: 100%;
      height: calc(var(--vh));
      background-color: var(--color-purple-400);
      transform: translateX(150%);
      transition-property: transform;
      transition-duration: 0.4s;
    }
  }

  &__list {
    display: flex;
    align-items: center;
    margin: 0 106.5px 0 0;

    @include media(desktop-medium) {
      margin: 0 30px 0 0;
    }

    @include media(tablet-wide) {
      z-index: -1;
      flex-direction: column;
      margin-right: 0;
    }
  }

  &__item {
    &:not(:last-of-type) {
      margin-right: rem(20);

      @include media(desktop-medium) {
        margin-right: rem(15);
      }

      @include media(tablet-wide) {
        margin-right: 0;
      }
    }

    @include media(tablet-wide) {
      margin-bottom: 30px;
    }
  }

  &__link {
    padding: 4px;
    font-size: clamp(18px, 0.4rem + 1vi, 20px);
    line-height: 1.21;
    font-weight: var(--fw-500);
    color: var(--color-white);
    text-transform: capitalize;
    transition: border-bottom 0.3s ease-in-out;

    &.true,
    &:hover {
      border-bottom: 2px solid var(--color-white);
    }
  }

  &__btns {
    display: flex;
    align-items: center;
    gap: 20px;

    @include media(tablet-wide) {
      flex-direction: column;
      gap: 30px;
    }
  }
}
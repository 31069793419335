@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.blog {
  &__inner {
    max-width: 1136px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: rem(32);
  }

  &__recents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: 0 57px;
  }

  &__img {
    margin-bottom: rem(46);
    border-radius: var(--radius-main);
  }

  &__name,
  &__link {
    font-weight: var(--fw-400);
    line-height: 1.2;
  }

  &__name {
    margin-bottom: rem(44);
    font-size: clamp(20px, 1rem + 1vi, 28px);
    color: var(--color-black);
  }

  &__link {
    font-size: clamp(18px, 1rem + 1vi, 23px);
    color: var(--color-purple-500);
  }

  &--resources {
    padding: rem(73) 0 rem(280);

    .blog__inner {
      max-width: 1216px;
    }

    h1 {
      margin-bottom: 20px;
      font-family: var(--font-family-secondary);
      font-size: clamp(30px, 2rem + 1vi, 38px);
      line-height: 1.21;
      letter-spacing: 0;
    }

    .blog__intro {
      width: 65%;
      margin-bottom: rem(60);
      font-family: var(--font-family-secondary);
      font-size: clamp(20px, 1rem + 1vi, 26px);
      color: var(--color-gray-700);
    }

    .blog__recents {
      gap: 40px 57px;
    }

    .blog__article {
      position: relative;
    }

    .blog__img {
      margin-bottom: 44px;
    }

    .blog__name {
      margin-bottom: 0;
      font-size: clamp(20px, 1rem + 1vi, 23px);
      color: var(--color-black);
    }

    .blog__link {
      position: absolute;
      inset: 0;
    }
  }

  @include media(tablet) {
    &__recents {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
      gap: rem(60);
    }

    &__recent {
      padding: 0 0 60px;
      box-shadow: var(--shadow-main);
      border-radius: var(--radius-main);
    }

    &__img {
      margin-bottom: 20px;
      width: 100%;
      height: auto;
      aspect-ratio: 6 / 3;
      border-radius: var(--radius-main) var(--radius-main) 0 0;
    }

    &__name,
    &__link {
      padding: 0 20px;
    }

    &--resources {
      padding: 44px 0;

      .blog__intro {
        width: 100%;
        margin-bottom: 30px;
      }

      .blog__recents {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }

      .blog__recent {
        border-radius: var(--radius-m);
      }

      .blog__img {
        border-radius: var(--radius-m) var(--radius-m) 0 0;
      }
    }
  }
  @include media(mobile-l) {
    &--resources {
      .blog__recents {
        grid-template-columns: 1fr;
      }
    }
  }

  @include media(mobile-m) {
    &__recent {
      padding: 0 0 30px;
    }
  }
}
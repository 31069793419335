@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.options {
  padding: rem(68) 0 rem(80);

  h2 {
    margin-bottom: rem(48);
    text-align: center;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: stretch;
    gap: rem(40);
  }

  &__item {
    position: relative;
    padding: 20px;
    border-radius: calc(var(--radius-m) * 2);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      right: 20px;
      top: 20px;
      height: 60px;
      width: 60px;
    }

    &--tailored {
      background-color: var(--color-purple-100);

      &::before {
        background-image: url("../images/icons/packages-icon.svg");
      }
    }

    &--additional {
      background-color: var(--color-red-100);

      &::before {
        right: 36px;
        background-image: url("../images/icons/integration-icon.svg");
      }
    }

    &--reporting {
      background-color: var(--color-red-120);

      &::before {
        top: 14px;
        background-image: url("../images/icons/specialized-icon.svg");
      }

      p {
        width: 90%;
      }
    }

    &--flexible {
      background-color: var(--color-blue-100);

      &::before {
        right: 36px;
        background-image: url("../images/icons/schedulign-icon.svg");
      }
    }

    &--tailored,
    &--additional {
      h3 {
        width: 65%;

        @include media(tablet) {
          width: 100%;
        }
      }
    }
  }

  h3 {
    margin-bottom: 16px;
    font-size: clamp(20px, 1rem + 1vi, 28px);
    line-height: 1.1;
    font-weight: var(--fw-600);
  }

  @include media(tablet) {
    padding: 44px 0;

    &__grid {
      grid-template-columns: 1fr;
    }

    &__item {
      padding-right: 94px;
    }
  }

  @include media(mobile-m) {
    &__item {
      padding: 10px;

      &::before {
        height: 50px;
        width: 50px;
        right: 10px !important;
        top: 10px;
      }

      h3 {
        padding-bottom: 40px;
        padding-right: 60px;
      }

      p {
        width: 100%;
      }
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.reviews {
  &__items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0 21px;
    align-items: stretch;
  }

  &__item {
    position: relative;
    padding-inline: rem(30);
    padding-block: rem(86) rem(30);
    background-color: var(--color-white);
    border-radius: var(--radius-m);
    box-shadow: var(--shadow-main);
  }

  &__avatar {
    position: absolute;
    top: -35px;
    padding: 6px;
    overflow: hidden;
    border-radius: var(--radius-round);
    background: var(--gradient-border);

    img {
      border-radius: inherit;
    }
  }

  &__info {
    display: grid;
    grid-template-columns: repeat(2, auto);
    margin-bottom: 20px;
  }

  &__position {
    grid-column: 1;
    grid-row: 2;
    font-size: 16px;
    line-height: 1.6;
  }

  &__rating {
    justify-self: flex-end;
  }

  &__txt {
    font-size: 16px;
    font-weight: var(--fw-600);
    line-height: 1.5;
    color: var(--color-blue-760);
  }

  &--home {
    padding: rem(232) 0 rem(130);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left top;

    h2 {
      text-align: right;
      margin-right: rem(232);
      margin-bottom: rem(135);
    }

    @include media(tablet-wide) {
      h2 {
        margin-right: 0;
      }

    }

    @include media(tablet) {
      padding: 44px 0;
      background-image: none !important;

      h2 {
        margin-bottom: 30px;
        text-align: center;
      }
    }
  }

  &--services {
    padding: rem(130) 0 rem(100);

    .reviews__inner {
      max-width: 1136px;
      width: 100%;
      margin: 0 auto rem(230);

      @include media(tablet-wide) {
        margin: 0 auto 60px;
      }
    }

    h2 {
      margin-bottom: rem(50);
    }

    .reviews__items {
      gap: 0 rem(100);

      @include media(tablet-wide) {
        gap: 30px;
      }
    }

    .reviews__item {
      padding: 0;
      background-color: transparent;
      box-shadow: none;
    }

    .reviews__position {
      grid-column: 1/ span 2;

      @include media(tablet-wide) {
        grid-column: auto;
      }
    }

    .reviews__accent {
      max-width: 680px;
      width: 100%;
      margin: 0 auto;
      font-size: clamp(32px, 2.5rem + 1vi, 48px);
      font-weight: var(--fw-500);
      color: var(--color-purple-500);
      text-align: center;

      @include media(mobile-m) {
        max-width: 100%;
        font-size: 32px;
      }
    }

    @include media(tablet) {
      padding: 44px 0;
    }
  }

  @include media(tablet-wide) {
    &__item {
      padding: 80px 10px 20px 10px;
    }

    &__info {
     grid-template-columns: 1fr;
    }

    &__rating {
      grid-row: 1;
      justify-self: flex-start;
      margin-bottom: 10px;
    }

    br {
      display: none;
    }

    &--services {

    }
  }

  @include media(tablet) {
    &__items {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;
      gap: 30px;
    }

    &__item {
      padding: 30px 10px 20px;

      &:last-of-type {
        grid-column: 1 / span 2;
        width: 50%;
      }
    }

    &__avatar {
      top: 10px;
      right: 10px;
    }
  }

  @include media(mobile-l) {
    &__items {
      grid-template-columns: 1fr;
    }

    &__item {
      &:last-of-type {
        grid-column: auto;
        width: 100%;
      }
    }

    &__avatar {
      width: 70px;
      height: 70px;

      img {
        width: 60px;
        height: 60px;
      }
    }

    &__info {
      padding-right: 80px;
    }
  }
}
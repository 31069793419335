@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.team {

  h2 {
    margin-bottom: 10px;
    line-height: 1;
    text-align: center;
  }

  &__subtitle {
    margin-bottom: 20px;
    font-size: clamp(20px, 1rem + 1vi, 23px);
    line-height: 1.2;
    font-weight: var(--fw-400);
    color: var(--color-blue-700)
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    justify-items: stretch;
    gap: 13px;
  }

  &__item {
    padding: rem(45) rem(39);
    border: 1px solid var(--color-purple-400);
    border-radius: calc(var(--radius-m) + 2px);
  }

  &__avatar {
    margin: 0 auto 20px;
    border-radius: var(--radius-round);
  }

  &__bio {
    margin: 0 auto;
    padding: 10px;
  }

  &__name {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 1.2;
    color: var(--color-black);
  }

  &__text {
    font-size: 23px;
  }

  @include media(tablet) {
    &__list {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &__item {
      padding: 20px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }

    &__avatar {
      margin-bottom: 0;
    }

    &__bio {
      padding: 0;
    }
  }

  @include  media(mobile-m) {
    &__item {
      flex-direction: column;
      text-align: center;
      justify-content: flex-start;
    }
  }
}
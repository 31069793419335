@use "../../utils/functions/"as*;
@use "../../utils/mixins/"as*;

.pricing {
  &__inner {
    max-width: 1130px;
    width: 100%;
    margin: 0 auto;
  }

  h2 {
    margin-bottom: rem(40);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    gap: 10px;
    max-width: 1063px;
    width: 100%;
    margin-bottom: 40px;
  }

  &__item {
    padding: 3px;
    background: var(--gradient-border);
    border-radius: var(--radius-m);
  }

  &__wrapper {
    height: 100%;
    padding: rem(45) rem(57);
    background-color: var(--color-white);
    border-radius: inherit;
  }

  &__name {
    margin-bottom: 20px;
    font-size: clamp(20px, 1rem + 1vi, 24px);
    line-height: 1.25;
    font-weight: var(--fw-600);
    text-align: center;
  }

  &__price {
    margin-bottom: 10px;
    font-size: clamp(56px, 3rem + 2vi, 72px);
    line-height: 1.1;
    text-align: center;
  }

  &__time {
    width: 100%;
    margin-bottom: 10px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 1.62;
    color: transparent;
    text-align: center;
    background: var(--gradient-border);
    background-clip: text;
    border-bottom: 1px solid var(--color-purple-510);
  }

  &__details {
    padding: 10px;

    p {
      font-size: clamp(18px, 1rem + 1vi, 23px);
      color: var(--color-black);
    }
  }

  &__txt {
    margin-bottom: 16px;
  }

  &__detail {
    display: flex;

    &:not(:last-of-type) {
      margin-bottom: 30px;
    }

    span {
      padding-right: 10px;
    }
  }

  &__desc {
    width: 90%;
    font-size: clamp(18px, 1rem + 1vi, 23px);
  }

  @include media(tablet) {
    padding: 80px 0 44px;

    &__wrapper {
      padding: 20px 15px 40px;
    }

    &__details {
      padding: 0;
    }

    &__desc {
      width: 100%;
    }
  }

  @include media(mobile-l) {
    &__grid {
      grid-template-columns: 1fr;
      grid-auto-rows: auto;
    }

    &__detail {
      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    }
  }
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.request {
  $root: &;
  padding: rem(86) 0;
  background: var(--gradient-secondary);

  h2 {
    margin-bottom: rem(48);
    text-align: center;
    color: var(--color-black);
    letter-spacing: -1px;
  }

  &__form {
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;
    padding: rem(34) rem(32);
    background-color: var(--color-white);
    border-radius: var(--radius-s);
    box-shadow: var(--shadow-main);
  }

  label {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }

    &:last-of-type {
      margin-bottom: 18px;
    }
  }

  input,
  textarea {
    display: inline-block;
    width: 100%;
    padding: 14px 16px;
    font-family: var(--font-family-secondary);
    font-size: 16px;
    line-height: 1;
    font-weight: var(--fw-400);
    color: var(--color-blue-700);
    background-color: var(--color-gray-400);
    border: none;
    border-radius: var(--radius-s);

    &::placeholder {
      color: var(--color-gray-700);
      opacity: 60%;
    }
  }

  textarea {
    min-height: rem(156);
    padding: 16px;
    resize: none;
  }

  @include media(tablet) {
    padding: rem(44) 0;

    h2 {
      margin-bottom: 30px;
    }

    &__form {
      padding: 20px 15px;
    }

    .btn {
      max-width: 100%;
    }
  }
}
/* stylelint-disable alpha-value-notation */
/* stylelint-disable color-function-notation */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --content-width: 1240px;
  --container-offset: 16px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));

  // font-family
  --font-family-primary: "Montserrat", sans-serif;
  --font-family-secondary: "Inter", sans-serif;

  // font-weight
  --fw-400: 400;
  --fw-500: 500;
  --fw-600: 600;
  --fw-700: 700;

  // color
  --color-white: #fff;
  --color-black: #111111;
  --color-dark: #000207;
  --color-gray-100: #fbf8ff;
  --color-gray-400: #f2f2f2;
  --color-gray-700: #2c2c2e;

  --color-red-100: #fdedfc;
  --color-red-120: #faedff;
  --color-red-391: #ff8bd1e8;
  --color-red-400: #fd4c5c;
  --color-red-600: #b03d48;
  --color-purple-100: #eeedfd;
  --color-purple-200: #e3cefb;
  --color-purple-391: #d299ffe8;
  --color-purple-400: #c796ff;
  --color-purple-510: rgba(120, 27, 255, 0.1);
  --color-purple-500: #781bff;
  --color-purple-503: rgba(120, 27, 255, 0.03);
  --color-purple-691: #9b8bffe8;
  --color-purple-800: #49009d;
  --color-blue-100: #e4efff;
  --color-blue-700: #000b33;
  --color-blue-770: rgba(0, 11, 51, 0.7);
  --color-blue-760: rgba(0, 11, 51, 0.6);

  // shadow
  --shadow-main: 0px 4px 40px 0px #2b59ff14;

  // radius
  --radius-round: 50%;
  --radius-main: 40px;
  --radius-secondary: 60px;
  --radius-m: 10px;
  --radius-s: 8px;
  --radius-xs: 3px;

  //gradient
  --gradient-main: linear-gradient(180deg, #ddc0ff 0%, #6615c4 100%);
  --gradient-secondary: linear-gradient(0.13deg, rgba(140, 48, 245, 0.4) 0.11%, rgba(200, 161, 244, 0.4) 111.96%);
  --gradient-section: linear-gradient(0.13deg, rgba(140, 48, 245, 0.2) 0.11%, rgba(200, 161, 244, 0.2) 111.96%);
  --gradient-accent: linear-gradient(0.13deg, rgba(254, 224, 91, 0.2) 0.11%, rgba(200, 161, 244, 0.2) 111.96%);
  --gradient-border: linear-gradient(90deg, #8c30f5 0%, #ff4b5c 100%);

  //icons
  --icon-bg-wave: url("data:image/svg+xml,%3Csvg width='1440' height='740' viewBox='0 0 1440 740' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.03' d='M725.828 705.729C412.2 764.881 135.9 735.691 0 705.729V0H1440V705.729C1345.85 683.917 1001.11 653.809 725.828 705.729Z' fill='%23781BFF'/%3E%3C/svg%3E");
  --icon-wave-reverse: url("data:image/svg+xml,%3Csvg width='1440' height='838' viewBox='0 0 1440 838' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath opacity='0.03' d='M714.172 28.5644C1033.07 -13.0934 1304.1 -5.77416 1440 28.5644V837.401H0V28.5644C94.147 53.5639 220.05 93.1122 714.172 28.5644Z' fill='%23781BFF'/%3E%3C/svg%3E%0A");
  --icon-egg: url("data:image/svg+xml,%3Csvg width='775' height='1116' viewBox='0 0 775 1116' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 300.5C0 300.5 698 -374.5 752.5 300.5C807 975.5 752.5 894 695 917.5C637.5 941 0 1351 0 917.5C0 484.001 0 300.5 0 300.5Z' fill='%23FBF8FF'/%3E%3C/svg%3E%0A");
  --icon-bubble: url("data:image/svg+xml,%3Csvg width='978' height='799' viewBox='0 0 978 799' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M978 583.687C978 583.687 97.1689 1067.11 28.3934 583.687C-40.382 100.263 28.3934 158.632 100.955 141.802C173.516 124.971 978 -168.664 978 141.802C978 452.267 978 583.687 978 583.687Z' fill='%23FBF8FF'/%3E%3C/svg%3E%0A");
  --icon-arrow: url("data:image/svg+xml,%3Csvg width='18' height='12' viewBox='0 0 18 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.1378 0.666676C8.91228 0.665324 8.68871 0.710139 8.47992 0.798549C8.27113 0.886958 8.08123 1.01722 7.92111 1.18188L1.06647 8.28812C0.906694 8.45377 0.779951 8.65042 0.693479 8.86684C0.607007 9.08326 0.5625 9.31523 0.5625 9.54948C0.5625 9.78374 0.607007 10.0157 0.693479 10.2321C0.779951 10.4486 0.906694 10.6452 1.06647 10.8108C1.22625 10.9765 1.41594 11.1079 1.6247 11.1975C1.83346 11.2872 2.05721 11.3333 2.28317 11.3333C2.50913 11.3333 2.73288 11.2872 2.94164 11.1975C3.1504 11.1079 3.34009 10.9765 3.49987 10.8108L9.1378 4.93042L14.7929 10.5799C14.9505 10.7616 15.1426 10.9077 15.3572 11.0089C15.5718 11.1101 15.8042 11.1644 16.0399 11.1683C16.2756 11.1721 16.5096 11.1255 16.7271 11.0314C16.9447 10.9373 17.1412 10.7976 17.3043 10.6211C17.4674 10.4447 17.5937 10.2352 17.6751 10.0059C17.7566 9.77654 17.7916 9.53224 17.7778 9.28825C17.764 9.04427 17.7019 8.80585 17.5952 8.58791C17.4885 8.36997 17.3396 8.17719 17.1577 8.02164L10.3031 1.16411C9.98971 0.850878 9.57308 0.673029 9.1378 0.666676Z' fill='%23781BFF'/%3E%3C/svg%3E%0A");
}
@use "../utils/functions/"as*;
@use "../utils/mixins/"as*;

.services {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: rem(164) 0 16px;

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: rem(40);
  }

  &__desc {
    margin-bottom: rem(94);
    color: var(--color-blue-770);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    align-items: stretch;
    justify-items: stretch;
    gap: rem(16) rem(16);
    max-width: 1149px;
    width: 100%;
  }

  &__item {
    position: relative;
    padding: 10px 10px 10px rem(123);

    &::before {
      @include cover-background;

      content: "";
      position: absolute;
      top: 10px;
      left: 10px;
      width: 86px;
      height: 86px;
    }

    &--bookkeeping::before {
      background-image: url("../images/icons/bookkeeping-icon.svg");
    }

    &--reporting::before {
      background-image: url("../images/icons/reporting-icon.svg");
    }

    &--tax::before {
      background-image: url("../images/icons/tax-icon.svg");
    }

    &--payroll::before {
      background-image: url("../images/icons/payroll-icon.svg");
    }

    &--auditing::before {
      background-image: url("../images/icons/auditing-icon.svg");
    }

    &--analysis::before {
      background-image: url("../images/icons/analysis-icon.svg");
    }
  }

  &__name {
    max-width: 164px;
    width: 100%;
  }

  &__text {
    font-size: 16px;
    line-height: 1.61;
  }

  &--spectrum {
    @include cover-background;

    max-width: unset;
    padding: rem(73) 0 20px;
    background-image: var(--icon-bg-wave);

    h2 {
      margin-bottom: rem(128);
      text-align: center;
      letter-spacing: -1px;

      @include media(tablet) {
        margin-bottom: 30px;
      }
    }

    .services__grid {
      margin: 0 auto;
    }
  }

  @include media(tablet) {
    padding: rem(68) 0 rem(44);

    &__grid {
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: auto;
      justify-items: center;
      margin-bottom: 30px;
    }

    &__nav {
      .btn {
        display: none;
      }
    }
  }

  @include media(mobile-l) {
    &__nav,
    &__desc {
      margin-bottom: 30px;
    }

    &__nav {
      flex-direction: column;
      align-items: flex-start;
    }

    &__grid {
      grid-template-columns: 1fr;
      margin-bottom: 30px;
    }

    &__item {
      padding: 10px 10px 10px 80px;

      &::before {
        top: 50%;
        width: 50px;
        height: 50px;
        transform: translateY(-50%);
      }
    }

    &__name {
      max-width: 100%;
    }
  }

  @include media(mobile-s) {
    &__item {
      padding: 60px 10px 10px 10px;

      &::before {
        top: 0;
        transform: translateY(0);
      }
    }
  }
}